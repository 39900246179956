import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import About from '../components/About'
import Footer from '../components/Footer'

const NotFoundPage = () => (
  <div className="Header notFoundPage">
    <SEO title="404: Not found" />
    <div className="wrapper">
      <h1 className="hero">
        Whoops! This isn't the page you were looking for.
      </h1>
      <a href="/" className="button">
        Take me to the campaign page
      </a>
    </div>
  </div>
)

export default NotFoundPage
